<template>
  <div class="flex bg-white tagBox" v-show="visitedTags.length">
    <div ref="tagContent" class="tagContent">
<!--      <el-icon class="tagContent_leftBtn control" @click="handleClickControl('left')"><DArrowLeft /></el-icon>-->
      <el-scrollbar ref="scrollbarRef" class="tagList" horizontal>
        <el-tag
            v-for="item in visitedTags"
            :key="item.fullPath"
            :type="router.currentRoute.value.fullPath === item.fullPath ? 'primary' : 'info'"
            :effect="router.currentRoute.value.fullPath === item.fullPath ? 'dark' : 'light'"
            :closable="!noClosableTags.includes(item.fullPath)"
            @close="handleCloseTag(item, '')"
        >
          <router-link :to="item.fullPath" @contextmenu.prevent="openMenu(item, $event)">{{ item.meta.title }}</router-link>
        </el-tag>
<!--        <div v-for="item in visitedTags" :key="item.fullPath" >-->
<!--          <router-link :to="item.fullPath" @contextmenu.prevent>{{ item.meta.title }}</router-link>-->
<!--          <span class="cursor-pointer" v-show="!noClosableTags.includes(item.fullPath)" @click="handleCloseTag(item, '')"><Close /></span>-->
<!--        </div>-->
      </el-scrollbar>
<!--      <el-icon class="tagContent_rightBtn control" @click="handleClickControl('right')"><DArrowRight /></el-icon>-->
    </div>
    <ul v-show="contentmenuVisible" class="contextMenu" :style="{ left: left + 'px', top: top + 'px' }">
      <li @click="handleRefresh">刷新</li>
    </ul>
  </div>
</template>

<script setup>
import { useTagsStore, initialTags } from '@/store/tags'
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { eventBus } from '@/views/eventBus'
import { Close } from '@element-plus/icons-vue'

const router = useRouter()
const store = useTagsStore()
const visitedTags = computed(() => store.visitedTags)
const scrollbarRef = ref(null)
const tagContent = ref(null)
const contentmenuVisible = ref(false)
const selectedTag = ref(null)
const left = ref(-100)
const top = ref(-100)

// const handleClickControl = (direction) => {
//   if (direction === 'left') {
//     scrollbarRef.value.setScrollLeft(0)
//   } else {
//     scrollbarRef.value.setScrollLeft(scrollbarRef.value.wrapRef.scrollWidth)
//   }
// }
const noClosableTags = reactive(initialTags.map(v => v.fullPath))

const handleCloseTag = (item, flag) => {
  if (item.fullPath.startsWith('/script/order') && flag !== 'emit') {
    ElMessageBox.confirm('系统可能不会保存您所做的更改', '确定关闭脚本？', {
      type: 'warning'
    }).then(() => {
      deleteTag()
    }).catch(() => {
    })
  } else {
    deleteTag()
  }
  function deleteTag() {
    store.deleteVisitedTags(item)
    store.deleteCachedTags(item)
    if (item.fullPath === router.currentRoute.value.fullPath) {
      const latestView = visitedTags.value.slice(-1)[0]
      router.push(latestView.fullPath)
    }
  }
}

const openMenu = (tag, e) => {
  let halfWidth
  if (window.innerWidth > 1500) {
    halfWidth = (window.innerWidth - 1200) / 2 + 180
  } else {
    halfWidth = 260
  }
  top.value = e.clientY
  left.value = e.clientX - halfWidth
  contentmenuVisible.value = true
  selectedTag.value = tag
}
const handleRefresh = () => {
  store.deleteCachedTags(selectedTag.value)
  const { path, query } = selectedTag.value
  nextTick(() => {
    router.replace({
      path: '/redirect' + path,
      query
    })
  })
}

const clickEvent = function() {
  if (contentmenuVisible.value) {
    contentmenuVisible.value = false
  }
}
onMounted(() => {
  document.addEventListener('click', clickEvent)
  eventBus.$on('deleteTag', (route) => handleCloseTag(route, 'emit'))
})
onBeforeUnmount(() => {
  document.removeEventListener('click', clickEvent)
})
</script>

<style lang="scss" scoped>
.tagBox {
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
  transition: all .2s;
  transform: translateZ(0);
  z-index: 100;
  //position: absolute;
  //top: 60px;
  width: calc(100% - 250px);
  max-width: calc(100% - 250px);
  .contextMenu {
    z-index: 1000;
    box-shadow: 2px 2px 3px 0 rgba(0,0,0,.3);
    position: absolute;
    font-size: 12px;
    padding: 5px 0;
    color: var(--itsop-font-2);
    border-radius: 4px;
    background: #fff;
    li {
      padding: 4px 8px;
      cursor: pointer;
      line-height: 1.5;
      &:hover {
        background: #eee;
      }
    }
  }
  .tagContent {
    //width: var(--itsop-content-width);
    //margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    .tagList {
      white-space: nowrap;
      flex: 1;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .control {
      background: white;
      height: 100%;
      width: 2em;
      box-shadow: 0 2px 8px rgba(0,0,0,.2);
      cursor: pointer;
      z-index: 1;
    }
  }
}
.el-tag {
  padding: 0 16px;
  &.is-closable {
    padding-right: 9px;
  }
}
.el-tag--info {
  background: #ffffff;
  border-radius: 0;
  border: none;
  color: var(--itsop-font-2);
}
.el-tag--primary {
  background: #fff;
  border-radius: 0;
  border: none;
  color: var(--el-color-primary);
  &:hover {
    background: var(--itsop-background);
  }
}
.el-tag :deep(.el-icon) {
  color: var(--el-color-info);
}
</style>
import { useTagsStore } from '@/store/tags'

const scrollMap = new Map()

export default function (to, from, next) {
  const tagsStore = useTagsStore()
  if (to.path === '/login') {
    tagsStore.clearVisitedTags()
    tagsStore.clearCachedTags()
  }
  if (to.path.startsWith('/script/order')) {
    if (!to.params.id) {
      to.meta.title = '新建脚本'
    } else {
      to.meta.title = `${to.query.isCopy === '1' ? '拷贝_' : '编辑_'}${to.query.name || ''}`
    }
  }
  if (!to.meta.isHidden) {
    scrollMap.set(from.fullPath, window.scrollY)
    tagsStore.addVisitedTags(to)
    tagsStore.addCachedTags(to)
  }
  next()
}

export {
  scrollMap
}
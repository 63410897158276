<template>
  <div class="contentBox">
    <div class="content flex items-center">
      <div class="flex-1 flex" style="width: calc(100% - 24px)">
        <p class="logo font-bold flex items-center"><img src="@/assets/logo.png" alt="" width="60" height="60"> IT SOP</p>
<!--        <router-link :class="router.currentRoute.path === '/workflow' ? 'active' : ''" to="/workflow" class="mr-8">工作流</router-link>-->
<!--        <router-link :class="router.currentRoute.path === '/script' ? 'active' : ''" to="/script">脚本</router-link>-->
        <tag-com />
      </div>
      <el-dropdown @command="handleCommand">
<!--        <span class="text-slate-600 text-xs cursor-pointer" @click="handleLogout">退出</span>-->
        <div>箭头</div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item><router-link to="/user/account">个人中心</router-link></el-dropdown-item>
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import TagCom from '../tag/index.vue'
import { logout } from '@/api'

const router = useRouter()
const handleLogout = () => {
  ElMessageBox.confirm('确定退出吗？', '提示', {
    type: 'warning'
  }).then(() => {
    logout({
      token: localStorage.getItem('token'),
    }).then(() => {
      router.replace('/login')
    })
  }).catch(() => {})
}

const handleCommand = (command) => {
  if (command === 'logout') {
    handleLogout()
  }
}
</script>

<style lang="scss" scoped>
.contentBox {
  transform: translateZ(0);
  box-shadow: 0 2px 8px var(--itsop-background);
  //z-index: 250;
  width: 100%;
  //position: absolute;
  //top: 0;
}
.content {
  width: var(--itsop-content-width);
  margin: 0 auto;
  height: 60px;
  line-height: 60px;
  .logo {
    width: 180px;
  }
  a {
    color: var(--itsop-font-2);
  }
  .router-link-active {
    font-weight: bolder;
    color: var(--el-color-primary);
  }
}
</style>
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const initialTags = [
  {
    fullPath: '/workflow',
    path: '/workflow',
    name: 'Workflow',
    meta: {
      title: '工作流'
    }
  },
  {
    fullPath: '/script',
    path: '/script',
    name: 'Script',
    meta: {
      title: '脚本'
    }
  }
]
export const useTagsStore = defineStore('tags', () => {
  const visitedTags = ref(JSON.parse(JSON.stringify(initialTags)))
  const cachedTags = ref(JSON.parse(JSON.stringify(initialTags)).map(v => v.fullPath))
  function addVisitedTags(route) {
    if (visitedTags.value.some(v => v.fullPath === route.fullPath)) return
    visitedTags.value.push(
      Object.assign({}, route)
    )
  }
  function deleteVisitedTags(route) {
    let index
    visitedTags.value.forEach((v, i) => {
      if (v.fullPath === route.fullPath) {
        index = i
      }
    })

    if (index >= 0) {
      visitedTags.value.splice(index, 1)
    }
  }
  function clearVisitedTags() {
    visitedTags.value = JSON.parse(JSON.stringify(initialTags))
  }

  function addCachedTags(route) {
    if (cachedTags.value.includes(route.fullPath)) return
    cachedTags.value.push(route.fullPath)
  }
  function deleteCachedTags(route) {
    if (cachedTags.value.indexOf(route.fullPath) !== -1) {
      cachedTags.value.splice(cachedTags.value.indexOf(route.fullPath), 1)
    }
  }
  function clearCachedTags() {
    // cachedTags.value = JSON.parse(JSON.stringify(initialTags)).map(v => v.fullPath)
    cachedTags.value = []
  }
  return {
    visitedTags,
    addVisitedTags,
    deleteVisitedTags,
    clearVisitedTags,
    cachedTags,
    addCachedTags,
    deleteCachedTags,
    clearCachedTags
  }
})
import { createRouter, createWebHashHistory } from 'vue-router'
import beforeEach, { scrollMap } from '@/router/filters/beforeEach'

const routes = [
  {
    path: '/redirect/:path(.*)',
    name: 'Redirect',
    meta: {
      isHidden: true
    },
    component: () => import('@/views/redirect/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      isHidden: true,
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/script',
    name: 'Script',
    meta: {
      title: '脚本',
      keepAlive: true
    },
    component: () => import('@/views/script/index.vue'),
  },
  {
    path: '/script/order/:id?',
    name: 'ScriptForm',
    meta: {
      title: '编辑脚本',
      keepAlive: true
    },
    component: () => import('@/views/script/components/scriptForm.vue')
  },
  {
    path: '/workflow',
    name: 'WorkFlow',
    meta: {
      title: '工作流',
      keepAlive: true
    },
    component: () => import('@/views/workflow/index.vue')
  },
  {
    path: '/user/account',
    name: 'Account',
    meta: {
      isHidden: true
    },
    component: () => import('@/views/user/account/index.vue')
  },
  {
    path: '/',
    redirect: '/workflow',
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: scrollMap.get(to.fullPath) || 0
      }
    }
  },
  routes
})
router.beforeEach(beforeEach)

export default router
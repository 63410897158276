import request from '@/utils/request'

export function userData(data) {
  return request({
    url: '/auth/validate',
    method: 'post',
    data
  })
}
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'POST',
    data
  })
}

export function logout(data) {
  return request({
    url: '/auth/logout',
    method: 'POST',
    data
  })
}

export function linktotp(data) {
  return request({
    url: '/user/linktotp',
    method: 'POST',
    data
  })
}

export function unlinktotp(data) {
  return request({
    url: '/user/unlinktotp',
    method: 'POST',
    data
  })
}

export function getSopList() {
  return request({
    url: '/autosop/list',
    method: 'post'
  })
}
export function createSop(data) {
  return request({
    url: '/autosop/create',
    method: 'post',
    data
  })
}
export function updateSop(data) {
  return request({
    url: '/autosop/update',
    method: 'post',
    data
  })
}
export function getSopById(id) {
  return request({
    url: '/autosop/info/byid/' + id
  })
}
export function getSopByName(name) {
  return request({
    url: '/autosop/info/byname/' + name
  })
}

export function getScriptList(params) {
  return request({
    url: '/autoscript/list',
    method: 'post',
    params
  })
}

export function addScript(data) {
  return request({
    url: '/autoscript/create',
    method: 'post',
    data
  })
}

export function updateScript(data) {
  return request({
    url: '/autoscript/update',
    method: 'post',
    data
  })
}

export function getScriptById(id) {
  return request({
    url: '/autoscript/info/byid/' + id
  })
}

export function getScriptByName(name) {
  return request({
    url: '/autoscript/info/byname/' + name
  })
}
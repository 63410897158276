import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

service.interceptors.request.use(config => {
  config.headers.token = localStorage.getItem('token')
  return config
})

service.interceptors.response.use(response => {
  if (response.data.code !== 200) {
    if (response.data.code === 499) {
      router.replace('/login')
    }
    ElMessage.error(response.data.msg)
    return Promise.reject(response.data)
  }
  return response.data
}, error => {
  if (error.response) {
    if (error.response.status === 499) {
      router.replace('/login')
    } else {
      ElMessage.error(error.message)
    }
  } else {
    ElMessage.error(error.message)
  }
  return Promise.reject(error.response.data || '')
})

export default service
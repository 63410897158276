<template>
  <header v-show="currentRoute !== '/login'">
    <header-com />
<!--    <tag-com />-->
  </header>
  <div class="main-container p-5" :style="{ background: currentRoute === '/login' ? 'transparent' : '#fff' }">
    <router-view v-slot="{ Component, route }">
      <keep-alive :include="cachedTags">
        <component :is="getComponent(Component, route)" :key="route.fullPath" />
      </keep-alive>
    </router-view>
  </div>
  <el-backtop :right="20" :bottom="100" :visibility-height="0" />
  <el-backtop :right="20" :bottom="50" :visibility-height="0" @click="scrollToBottom">
    <template #default>
      <el-icon><CaretBottom /></el-icon>
    </template>
  </el-backtop>
</template>

<script>
import HeaderCom from './components/header/index.vue'
import TagCom from '@/components/tag/index.vue'
import { useTagsStore } from '@/store/tags'
import { useRoute } from 'vue-router'
import { computed, h } from 'vue'
import { userData } from '@/api'
import { useGlobalStore } from '@/store'

const wrapperMap = new Map()
export default {
  name: 'App',
  components: {
    HeaderCom,
    TagCom
  },
  computed: {
    cachedTags() {
      const store = useTagsStore()
      return store.cachedTags ? store.cachedTags : []
    }
  },
  setup() {
    const route = useRoute()
    const currentRoute = computed(() => route.path)
    const routeName = computed(() => route.name)
    const topMargin = computed(() => currentRoute.value === '/login' ? '0' : '76px')

    const getComponent = (component, route) => {
      let wrapper
      if (component) {
        const wrapperName = route.fullPath
        if (wrapperMap.has(wrapperName)) {
          wrapper = wrapperMap.get(wrapperName)
        } else {
          wrapper = {
            name: wrapperName,
            render() {
              return h(component)
            }
          }
        }
        wrapperMap.set(wrapperName, wrapper)
      }
      return h(wrapper)
    }

    const scrollToBottom = () => {
      // 获取当前页面的可滚动区域的总高度
      const scrollHeight = document.documentElement.scrollHeight;
      // 获取当前的可视区域高度
      const clientHeight = document.documentElement.clientHeight;

      // 使用 window.scrollTo 并指定行为为 "smooth" 实现平滑滚动
      window.scrollTo({
        top: scrollHeight - clientHeight,
        behavior: 'smooth'
      })
    }

    const store = useGlobalStore()
    const getUserData = async () => {
      try {
        const res = await userData({
          token: localStorage.getItem('token') || ''
        })
        store.setUserData(res.data)
      } catch (e) {
        console.error(e)
      }
    }
    getUserData()

    return {
      currentRoute,
      routeName,
      topMargin,
      route,
      getComponent,
      scrollToBottom
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    height: 60px;
    z-index: 100;
  }
  .main-container {
    width: var(--itsop-content-width);
    margin: v-bind(topMargin) auto 0;
    min-height: calc(100vh - 76px);
  }
}
</style>

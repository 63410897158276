import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import locale from 'element-plus/es/locale/lang/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from '@/router'
import { createPinia } from 'pinia'

import '@/styles/index.scss'
import 'element-plus/dist/index.css'

const app = createApp(App)
const pinia = createPinia()

for (let [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app
  .use(router)
  .use(pinia)
  .use(ElementPlus, {
    size: 'default',
    locale
  })
  .mount('#app')
